import React, {FC} from 'react'
import styled from 'styled-components'

import {ChevronDown} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {NakedButton} from '../button'
import {Count} from '../count'
import {focusRing} from '../focus-ring'
import {Inline} from '../inline'
import {Spinner} from '../spinner'

const Button = styled(NakedButton)<{
    $moreFilters?: boolean
    $hasAppliedFilters?: boolean
    $maxWidth?: string
}>`
    ${focusRing('offset')}
    display: inline-flex;
    align-items: center;
    max-width: ${({$maxWidth}) => $maxWidth};
    height: ${tokens.heightInputAndButton};
    padding-top: 0;

    /* For the moreFilters version, we show a ChevronDown icon which demands less spacing */
    padding-right: ${({$moreFilters}) => ($moreFilters ? tokens.spacing8 : tokens.spacing12)};
    padding-bottom: 0;
    padding-left: ${tokens.spacing12};
    color: ${({$hasAppliedFilters}) =>
        $hasAppliedFilters
            ? tokens.colorContentInteractiveSelected
            : tokens.colorContentInteractiveQuiet};
    font-size: ${tokens.fontMedium};
    background-color: ${({$hasAppliedFilters}) =>
        $hasAppliedFilters
            ? tokens.colorBackgroundInteractiveSelected
            : tokens.colorBackgroundInteractive};
    border: ${tokens.sizeBorderDefault} solid;
    border-color: ${({$hasAppliedFilters}) => {
        if ($hasAppliedFilters) {
            return `${tokens.colorBorderInteractiveSelected}`
        }
        return `${tokens.colorBorderInteractiveQuiet}`
    }};
    border-radius: ${tokens.arc8};
    transition: ${tokens.motionWithinSmallShort};
    transition-property: background-color, border, color;

    &:hover {
        color: ${({$hasAppliedFilters}) =>
            $hasAppliedFilters
                ? tokens.colorContentInteractiveSelectedHover
                : tokens.colorContentInteractiveHover};
        background-color: ${({$hasAppliedFilters}) =>
            $hasAppliedFilters
                ? tokens.colorBackgroundInteractiveSelectedHover
                : tokens.colorBackgroundInteractive};
        border-color: ${({$hasAppliedFilters}) =>
            $hasAppliedFilters
                ? tokens.colorBorderInteractiveSelectedHover
                : tokens.colorBorderInteractiveHover};
    }
`

const Text = styled.span`
    /* Only takes effect if maxWidth is supplied to the Button */
    overflow: hidden;
    font-size: ${tokens.fontMedium};
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Content = styled(Inline).attrs({
    as: 'span',
    space: 6,
    alignY: 'center'
})`
    overflow: hidden;
`

export interface FilterButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    /** Commonly used for a "More filters" filter with a chevron on the right*/
    moreFilters?: boolean
    /** Used to change the appearance of the button to make it clear that its filter menu contains applied filters. */
    hasAppliedFilters?: boolean
    /** Used to indicate the number of filters applied. We only use it if more than one filter can be applied. */
    numAppliedFilters?: number
    /** Used if there are many filter categories and limited horizontal space. This will cause ellipsis text for category if the specified max width is exceeded. Should only be used in combination with `hasAppliedFilters` */
    maxWidth?: string
    /** If `true` the button will show a loading animation. */
    loading?: boolean
    /** Will override children and display this text next to the loading animation */
    loadingText?: string
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

export const FilterButton: FC<FilterButtonProps> = React.forwardRef(
    (
        {
            children,
            hasAppliedFilters,
            numAppliedFilters,
            moreFilters,
            maxWidth,
            loading,
            loadingText,
            skeleton = false,
            ...rest
        },
        ref
    ) => (
        <Button
            {...rest}
            $maxWidth={maxWidth}
            $hasAppliedFilters={hasAppliedFilters}
            $moreFilters={moreFilters}
            ref={ref as any}
            data-telescope="filter-button"
            skeleton={skeleton}
        >
            <Content>
                {loading && <Spinner />}
                <Text>{loading && loadingText ? loadingText : children}</Text>
                {hasAppliedFilters && !!numAppliedFilters && numAppliedFilters > 0 && (
                    <Count>{numAppliedFilters}</Count>
                )}
                {moreFilters && <ChevronDown size={16} />}
            </Content>
        </Button>
    )
)
