import React, {ChangeEvent, MouseEvent, HTMLProps, useEffect, useRef} from 'react'
import styled, {css} from 'styled-components'

import {CheckSmall, Minus} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {focusRingSibling} from '../focus-ring'
import {Skeleton} from '../skeleton'
import {Stack, StackProps} from '../stack'
import {Text} from '../text'

export interface CheckboxProps
    extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'onClick' | 'as' | 'ref' | 'type'> {
    /**
     * The callback invoked when the checked state of the `Checkbox` changes.
     * @default noop
     */
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void

    /**
     * The callback invoked when the wrapping label is clicked.
     */
    onClick?: (event: MouseEvent<HTMLLabelElement>) => void

    /**
     * A boolean flag that shows the input in an error state if true.
     */
    isInvalid?: boolean

    /**
     * A boolean flag that shows the input in an indeterminate state if true.
     */
    indeterminate?: boolean
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

export type CheckboxGroupProps = {children: React.ReactNode} & Pick<StackProps, 'marginY'>
export const CheckboxGroup = ({children, ...rest}: CheckboxGroupProps) => (
    <Stack space={16} data-telescope="checkbox-group" {...rest}>
        {children}
    </Stack>
)

export const Checkbox = ({
    className,
    checked,
    disabled,
    isInvalid,
    indeterminate,
    children,
    onClick,
    onChange = () => {},
    skeleton = false,
    ...props
}: CheckboxProps) => {
    const checkboxRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = !!indeterminate
        }
    }, [indeterminate])

    return (
        <CheckboxContainer
            css={{pointerEvents: skeleton ? 'none' : 'auto'}}
            className={className}
            onClick={onClick}
            disabled={disabled}
        >
            <HiddenCheckbox
                ref={checkboxRef}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                aria-invalid={isInvalid}
                {...props}
            />
            <Skeleton loading={skeleton}>
                <StyledCheckbox
                    disabled={disabled}
                    checked={!!checked}
                    $indeterminate={indeterminate}
                    $hasLabel={!!children}
                    data-telescope="checkbox"
                    $isInvalid={isInvalid}
                >
                    {indeterminate ? (
                        <IndeterminateIcon disabled={disabled} />
                    ) : (
                        <Icon disabled={disabled} checked={checked} />
                    )}
                </StyledCheckbox>
            </Skeleton>
            <Skeleton loading={skeleton} borderRadius={4}>
                {children}
            </Skeleton>
        </CheckboxContainer>
    )
}

Checkbox.displayName = 'Checkbox'

////////////////////////////////////////////////////////////////////////////////

type InnerProps = Pick<CheckboxProps, 'checked' | 'disabled'> & {
    $isInvalid?: boolean
    $indeterminate?: boolean
}

const CheckboxContainer = styled(Text).attrs({
    as: 'label'
})<Pick<InnerProps, 'disabled'>>`
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    color: ${({disabled}) =>
        disabled ? tokens.colorContentInteractiveDisabled : tokens.colorContentInteractive};
    cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};

    &:hover:not([disabled]) {
        color: ${tokens.colorContentInteractiveHover};
    }
`

const IndeterminateIcon = styled(Minus).attrs({size: 16})<InnerProps>`
    fill: ${({disabled}) =>
        disabled ? tokens.colorContentInteractiveDisabled : tokens.colorContentInteractiveSelected};
`

const Icon = styled(CheckSmall).attrs({size: 16})<InnerProps>`
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    fill: ${({disabled}) =>
        disabled ? tokens.colorContentInteractiveDisabled : tokens.colorContentInteractiveSelected};
`

const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})<CheckboxProps>`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: none;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
`

const borderColor = ({disabled, checked, $indeterminate, $isInvalid}: InnerProps) => {
    if (disabled) {
        return tokens.colorBorderInteractiveDisabled
    }

    if ($isInvalid) {
        return tokens.colorBorderNegative
    }

    return checked || $indeterminate
        ? tokens.colorBorderInteractiveSelected
        : tokens.colorBorderInteractive
}

const backgroundColor = ({checked, disabled, $indeterminate, $isInvalid}: InnerProps) => {
    if (disabled) {
        return tokens.colorBackgroundInteractiveDisabled
    }

    const hasValue = checked || $indeterminate

    if ($isInvalid && hasValue) {
        return tokens.colorBackgroundNegative
    }

    return hasValue ? tokens.colorBackgroundInteractiveSelected : tokens.colorBackgroundInteractive
}

const StyledCheckbox = styled.div<InnerProps & {$hasLabel: boolean}>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    margin-top: 1px;
    transition: ${tokens.motionWithinSmallShort};
    transition-property: color, border-color, background-color;
    ${focusRingSibling(HiddenCheckbox)}

    &:hover:not([disabled]) {
        ${({checked, $isInvalid}) => {
            if ($isInvalid && checked) {
                return css`
                    color: ${tokens.colorContentInteractiveSelected};
                    background-color: ${tokens.colorBackgroundNegativeHover};
                    border-color: ${tokens.colorBorderNegativeHover};
                `
            }

            if (checked) {
                return css`
                    color: ${tokens.colorContentInteractiveSelected};
                    background-color: ${tokens.colorBackgroundInteractiveSelected};
                    border-color: ${tokens.colorBorderInteractiveHover};

                    > svg {
                        color: ${tokens.colorContentInteractiveSelected};
                    }
                `
            }

            return css`
                border-color: ${$isInvalid
                    ? tokens.colorBorderNegativeHover
                    : tokens.colorBorderInteractiveHover};
            `
        }}
    }

    ${({$hasLabel}) =>
        $hasLabel &&
        css`
            margin-right: ${tokens.spacing12};
        `}

    width: 20px;
    height: 20px;
    box-sizing: border-box;
    background-color: ${backgroundColor};
    border: ${tokens.sizeBorderThick} solid ${borderColor};
    border-radius: ${tokens.arc4};
`
