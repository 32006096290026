/**
 * @overview Callout
 * @stage Proposed
 * @author Sanjiv (@sanjivkrish)
 * @designer Casper
 * @spec https://www.notion.so/Callout-cfba31e9ea1a4f97bd1fb3d87238f45d
 */
import React, {HTMLAttributes} from 'react'
import styled from 'styled-components'

import {Info, Warning, Rocket, Close, CircleCheck} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {theme} from '../../utils'
import {useLocalisation} from '../../utils/localisation'
import {Box} from '../box'
import {ButtonLink} from '../button/button.styles'
import {IconButton, IconButtonProps, IconButtonStyles} from '../icon-button'
import {Inline} from '../inline'
import {StyledLink} from '../link'
import {Skeleton} from '../skeleton'
import {Stack} from '../stack'
import {Text} from '../text'

export type CalloutVariants = 'neutral' | 'negative' | 'warning' | 'positive' | 'info' | 'discover'

export interface CalloutProps {
    /**
     * Option to change the look of the callout
     * @default 'neutral'
     */
    variant?: CalloutVariants
    /**
     * Icon to override the default Icon
     */
    Icon?: React.ComponentType
    /**
     * The contents of the callout
     */
    children: React.ReactNode
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

const variantIcons: Record<CalloutVariants, React.ComponentType> = {
    neutral: Info,
    info: Info,
    warning: Warning,
    negative: Warning,
    positive: CircleCheck,
    discover: Rocket
}

export const Callout = ({
    variant = 'neutral',
    Icon,
    children,
    className,
    skeleton = false,
    ...props
}: CalloutProps & HTMLAttributes<HTMLDivElement>) => {
    const CalloutIcon = Icon ?? variantIcons[variant]

    return (
        <Skeleton loading={skeleton}>
            <CalloutBase
                {...props}
                $variant={variant}
                data-telescope="callout"
                className={`${className || ''} ${theme[variant]}`}
            >
                {CalloutIcon && <CalloutIcon />}
                {children}
            </CalloutBase>
        </Skeleton>
    )
}

export const CalloutGroup = ({children}: {children: React.ReactNode}) => {
    return <Stack space={16}>{children}</Stack>
}

const StyledText = styled(Text)`
    flex-grow: 1;
    /* The text is 22px heigh whereas icon and CTA is 24px. This
    is a bit hacky, but we should be safe with any regressions
    as we use Chromatic to do visual diffing. */
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    margin-top: 1px;
    text-align: left;
`

const StyledContent = styled(Box)`
    flex-grow: 1;
`

const StyledContentHeading = styled(Text)`
    font-weight: ${tokens.fontWeightSemibold};
`

type CloseButtonProps = Omit<IconButtonProps, 'variant' | 'size' | 'Icon' | 'tooltipProps'>
const CloseButton = (props: CloseButtonProps) => {
    const translations = useLocalisation()

    return (
        <IconButton
            {...props}
            variant="secondary"
            size="extraSmall"
            Icon={Close}
            tooltipProps={{
                dangerouslyOmitTooltip: true,
                'aria-label': translations.Callout.CloseButton
            }}
        />
    )
}

const CalloutBase = styled(Inline)<{$variant: CalloutVariants}>`
    gap: ${tokens.spacing12};
    width: 100%;
    padding: ${tokens.spacing8} ${tokens.spacing12};
    border-radius: ${tokens.arc8};
    color: ${tokens.colorContentStatic};
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStatic};

    &:has(${IconButtonStyles}) {
        padding-right: ${tokens.spacing8};
    }

    a,
    ${StyledLink}, ${ButtonLink} {
        text-decoration: underline;
    }
`

Callout.Content = StyledContent
Callout.ContentHeading = StyledContentHeading
Callout.Text = StyledText
Callout.CloseButton = CloseButton

Callout.displayName = 'Callout'
